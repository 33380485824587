<template>
  <div>
    <b-modal
      id="addTemplateModal"
      title="Add Template"
      size="md"
      hide-footer
    >
      <validation-observer
        ref="addTemplateModal"
      >
        <b-form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="addTemplate"
        >
          <!-- Services -->
          <b-row>
            <b-col cols="12">
             <b-form-group>
                <b-form-radio-group
                  id="radio-slots"
                  v-model="form.type"
                >
                  <b-form-radio value="1">
                    {{ t('Information') }}
                  </b-form-radio>
                  <b-form-radio value="2">
                    {{ t('Proposal') }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Select Service"
                label-for="vi-select-service"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Select Services"
                  vid="vi-select-service"
                  rules="required"
                >
                  <v-select
                    v-model="form.service_id"
                    class="p-0 border-0"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="service_name"
                    :reduce="srv => srv.id"
                    placeholder="Select Service"
                    :options="servicesList"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Template Title -->
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="t('Template Title')"
                label-for="vi-Options"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Template Title"
                  vid="vi-Template-Title"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-Template-Title"
                      v-model="form.template_name"
                      type="text"
                      autocomplete="off"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Template Title')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-col
            cols="12"
            class="text-center mt-2 mb-3"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              {{ t('Submit') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="resetQuestionForm"
            >
              {{ t('Cancel') }}
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils } from '@core/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import constants from '@/constants'
import {
  encrypt,
  failedToast, hideLoader, showLoader, successToast,
} from '@/utils/common'

export default {
  name: 'AddTemplate',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    edittemplate: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const { t } = useUtils()
    return {
      t,
    }
  },
  data() {
    return {
      required,
      groupList: [],
      servicesList: [],
      typeList: [],
      options: [],
      selectFromMasterList: [],
      form: {
        template_id: '',
        service_id: '',
        template_name: '',
        type: '1',
      },
    }
  },
  watch: {
    edittemplate: {
      deep: true,
      handler(val) {
        if (Object.values(val).length) {
          this.form.template_id = val.template_id
          this.form.service_id = val.service_id
          this.form.template_name = val.template_name
          this.form.type = val.type
        } else {
          this.form.template_id = ''
          this.form.service_id = ''
          this.form.template_name = ''
          this.form.type = ''
        }
      },
    },
  },
  created() {
    store.dispatch('userManage/getServices').then(response => {
      // eslint-disable-next-line eqeqeq
      if (response.data.code == constants.SUCCESS) { this.servicesList = response.data.data }
    })
  },
  mounted() {
  },
  methods: {
    resetQuestionForm() {
      this.$bvModal.hide('addTemplateModal')
    },
    addTemplate() {
      this.$refs.addTemplateModal.validate().then(success => {
        if (success) {
          this.addTemplateStore()
        }
      })
    },
    async addTemplateStore() {
      showLoader()
      await store.dispatch('questionsStore/saveTemplate', { payload: this.form, secretKey: encrypt(this.form) }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          hideLoader()
          successToast(response.data.msg)
          this.$root.$emit('refresTemplateList')
          this.resetQuestionForm()
        } else {
          hideLoader()
          failedToast(response.data.msg)
          this.resetQuestionForm()
        }
        hideLoader()
      }).catch(e => {
        failedToast(e.msg)
        this.resetQuestionForm()
      })
      hideLoader()
    },
  },
}
</script>

<style>

</style>
