<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <b-button
        v-b-toggle.templateFilter
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>

      <b-button
        v-if="checkPermission(constants.ADD_TEMPLATE)"
        variant="primary"
        @click="$bvModal.show('addTemplateModal')"
      >
        <feather-icon icon="PlusIcon" />
        {{ t('Add New') }}
      </b-button>
    </div>
    <b-collapse
      id="templateFilter"
      class="mt-2"
    >
      <TemplateList
        :form="filterForm"
        @refresTemplateList="refresTemplateList"
      />
    </b-collapse>

    <!-- Template List Table -->

    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="userFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(template_service_name)="data">
          <span><b-badge variant="success">{{ data.item.template_service_name }}</b-badge></span>
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status === constants.USER_STATUS_ACTIVE"
            class="text-success cursor-pointer"
            @click="changeStatus(data.item.status, data.item.template_id )"
          >Active</span>
          <span
            v-if="data.item.status === constants.USER_STATUS_INACTIVE"
            class="text-danger cursor-pointer"
            @click="changeStatus(data.item.status, data.item.template_id )"
          >In-Active</span>
        </template>
        <template #cell(created_at)="data">
          {{ dateRegFormat(data.item.created_at) }}
        </template>
        <template #cell(updated_at)="data">
          {{ dateRegFormat(data.item.updated_at) }}
        </template>
        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            {{ t('No Record Found') }}
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">
        <b-col
          cols="4"
          class="d-flex"
        >
          <div class="align-self-center pr-2">
            {{ t('Total Entries') + " " + items.total }}
          </div>
          <b-form-group class="align-self-center mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="filterForm.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getTemplateList"
            />
          </b-form-group>

        </b-col>
        <b-col cols="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>

    <vue-context
      ref="templateContextMenu"
    >
      <li
        v-for="data in menuData"
        :key="data.text"
      >
        <b-link
          v-if="data.id == 3 && checkPermission(constants.TEMPLATE_QUESTION)"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link
          v-if="data.id == 2 && checkPermission(constants.ADD_TEMPLATE_QUESTION)"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link
          v-else-if="data.id !== 3 && data.id !== 2"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

    <AddTemplateModal :edittemplate="valuesContext" />
    <AddQuestionModal :addtempques="valuesContext" />
    <b-modal
      v-if="valuesContext"
      id="viewTemplateModal"
      size="lg"
      hide-footer
    >
      <template #modal-title>
        {{ valuesContext.template_name }} <span class="ml-1"> <b-badge variant="success"> {{ valuesContext.template_service_name }} </b-badge></span>
      </template>
      <ViewTemplate
        :template_ids="valuesContext.template_id"
        :is-submit-btn="false"
      />
    </b-modal>
  </div>
</template>

<script>
import 'bootstrap-vue'
import VueContext from 'vue-context'
import { useUtils } from '@core/libs/i18n'
import axios from '@/utils/axiosCompany'
import AddTemplateModal from './AddTemplateModal.vue'
import AddQuestionModal from './CreateQuestionModal.vue'
import ViewTemplate from './ViewTemplate.vue'
import {
  hideLoader, showLoader, tableFilter, dateRegFormat, failedToast,
} from '@/utils/common'
import TemplateList from './TemplateFilter.vue'
import store from '@/store'
import constants from '@/constants'
import checkPermissions from '@/utils/checkPermissons'

export default {
  name: 'TemplatesList',
  components: {
    AddTemplateModal,
    TemplateList,
    AddQuestionModal,
    VueContext,
    ViewTemplate,
  },
  data() {
    return {
      constants,
      tableFilter,
      items: [],
      valuesContext: {},
      menuData: [],
      filterForm: {
        params: {
          template_name: '',
          service_id: '',
          status: '',
          period: '',
          startDate: '',
          endDate: '',
        },
        sortBy: 'id',
        sortOrder: 'desc',
        size: '10',
        pageNumber: '',
      },
    }
  },
  computed: {
    userFields() {
      return [
        { key: 'id', label: this.$i18n.t('Id'), sortable: true },
        { key: 'template_name', label: this.$i18n.t('Template Name'), sortable: true },
        { key: 'template_service_name', label: this.$i18n.t('Service Name'), sortable: true },
        { key: 'status', label: this.$i18n.t('Status'), sortable: true },
        { key: 'created', label: this.$i18n.t('Created By'), sortable: false },
        { key: 'created_at', label: this.$i18n.t('Created Date'), sortable: false },
        { key: 'updated', label: this.$i18n.t('Updated By'), sortable: false },
        { key: 'updated_at', label: this.$i18n.t('Updated Date'), sortable: false },
      ]
    },
    menuOptions() {
      return [
        { id: 1, icon: 'EditIcon', text: this.$i18n.t('Edit Template') },
        { id: 2, icon: 'PlusIcon', text: this.$i18n.t('Add Question') },
        { id: 3, icon: 'EyeIcon', text: this.$i18n.t('View Questions') },
        { id: 4, icon: 'EyeIcon', text: this.$i18n.t('View Template') },
      ]
    },
  },
  setup() {
    const { t } = useUtils()
    return {
      t,
    }
  },
  mounted() {
    this.$root.$on('refresTemplateList', () => {
      this.getTemplateList()
    })
    this.$root.$on('bv::modal::hidden', () => {
      this.valuesContext = {}
    })
  },
  methods: {
    refresTemplateList() {
      this.getTemplateList()
    },
    checkPermission(val) {
      return checkPermissions(val)
    },
    dateRegFormat(date) {
      return dateRegFormat(date)
    },
    sortChanged(val) {
      this.filterForm.sortBy = val.sortBy
      this.filterForm.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getTemplateList()
    },
    async getTemplateList() {
      showLoader()
      await store.dispatch('questionsStore/getTemplateList', this.filterForm).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.items = response.data.data
          hideLoader()
        }
      })
      hideLoader()
    },
    async getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      await axios.post(`/template/list-template?page=${page}`, this.filterForm, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
          hideLoader()
        })
      hideLoader()
    },
    changeStatus(status, id) {
      // eslint-disable-next-line eqeqeq
      if (status == 'A') {
        this.$swal({
          title: this.$i18n.t('Are you sure ?'),
          text: this.$i18n.t('You want to Deactivate this Template!'),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$i18n.t('Cancel'),
          confirmButtonText: this.$i18n.t('Yes'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.deactivateTemplate(id)
          }
        })
      } else {
        this.$swal({
          title: this.$i18n.t('Are you sure ?'),
          text: this.$i18n.t('You want to Activate this Template!'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('Yes'),
          cancelButtonText: this.$i18n.t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.activateTemplate(id)
          }
        })
      }
    },
    async deactivateTemplate(id) {
      showLoader()
      await store.dispatch('questionsStore/changeStatusTemplate', { status: 'I', template_id: id }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.getTemplateList()
          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Success'),
            text: this.$i18n.t('Template is successfully Deactivated'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          hideLoader()
        } else {
          failedToast(response.data.msg)
        }
      }).catch(e => {
        failedToast(e.msg)
      })
      hideLoader()
    },
    async activateTemplate(id) {
      showLoader()
      await store.dispatch('questionsStore/changeStatusTemplate', { status: 'A', template_id: id }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.getTemplateList()
          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Success'),
            text: this.$i18n.t('Template is successfully Activated'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          hideLoader()
        } else {
          failedToast(response.data.msg)
        }
      }).catch(e => {
        failedToast(e.msg)
      })
      hideLoader()
    },
    contextmenu(item, index, event) {
      this.menuData = []
      event.preventDefault()
      if (!item.is_edit || !this.checkPermission(constants.EDIT_TEMPLATE)) {
        this.menuData = this.menuOptions.filter(val => val.id !== 1)
      } else {
        this.menuData = this.menuOptions
      }
      this.valuesContext = item
      this.$refs.templateContextMenu.open(event, item)
    },
    optionClicked(id) {
      if (id === 1) {
        this.$bvModal.show('addTemplateModal')
      } else if (id === 2) {
        this.$bvModal.show('createQuesitonModal')
      } else if (id === 3) {
        this.$router.push({ name: 'questions-list/', params: { id: this.valuesContext.template_id } })
      } else if (id === 4) {
        this.$bvModal.show('viewTemplateModal')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-context.scss';

</style>
